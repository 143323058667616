import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Main from './Main'
import Spinner from '../Spinner'
import { ExpiredDocumentsAlert, CateringAgreementAlert } from 'app/alerts'
import ErrorBoundary from 'app/theme/ErrorBoundary'
import HeaderBanner from 'app/theme/HeaderBanner'
import env from 'env'

export default function Layout(props) {
  const history = useHistory()

  useEffect(() => {
    if (env.APP_ENV == 'production') {
      const script = document.createElement('script')
      const script2 = document.createElement('script')

      script.innerHTML = '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
      script.async = true

      script2.innerHTML = "window.Beacon('init', '15594578-80ee-4d55-8d9b-9e7daf933440')"
      script2.async = true

      
      document.body.appendChild(script)
      document.body.appendChild(script2)

      return () => {
        document.body.removeChild(script)
        document.body.removeChild(script2)
      }
    }
  }, [])

  const bannerEnabled = env.HEADER_BANNER_ENABLED === '1'

  return (
    <div className={[props.containerClassName, 'min-h-screen flex flex-col']}>
      {bannerEnabled && (
        <HeaderBanner message={env.HEADER_BANNER_CONTENT} linkUrl={env.HEADER_BANNER_LINK_URL} linkText={env.HEADER_BANNER_LINK_TEXT} />
      )}
      <Header key={history.location.pathname}/>
      {props.isLoading ? (
        <Main>
          <Spinner className="mx-auto mt-10" />
        </Main>
      ) : (
        <>
          <ExpiredDocumentsAlert />
          <CateringAgreementAlert />
          <Main className={props.className}>
            <ErrorBoundary>
              {props.children}
            </ErrorBoundary>
          </Main>
        </>
      )}
      <Footer />
    </div>
  )
}
