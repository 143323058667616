let env

if (typeof window !== 'undefined' && window.__APP_STATE__) {
  env = {
    ...window.__APP_STATE__.env,
  }
} else {
  env = {
    APP_VERSION: process.env.APP_VERSION,
    HOST: process.env.HOST,
    APP_ENV: process.env.APP_ENV,
    API_URL: process.env.API_URL,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
    MEDIA_URL: process.env.MEDIA_URL,
    RH_URL: process.env.RH_URL,
    CATERING_URL: process.env.CATERING_URL,
    SENTRY_DSN: process.env.SENTRY_DSN,
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    POSTHOG_TOKEN: process.env.POSTHOG_TOKEN,
    POSTHOG_HOST: process.env.POSTHOG_HOST,
    HEADER_BANNER_ENABLED: process.env.HEADER_BANNER_ENABLED,
    HEADER_BANNER_LOGGED_IN: process.env.HEADER_BANNER_LOGGED_IN,
    HEADER_BANNER_PREFIX_KEY: process.env.HEADER_BANNER_PREFIX_KEY,
    HEADER_BANNER_CONTENT: process.env.HEADER_BANNER_CONTENT,
    HEADER_BANNER_LINK_URL: process.env.HEADER_BANNER_LINK_URL,
    HEADER_BANNER_LINK_TEXT: process.env.HEADER_BANNER_LINK_TEXT,
  }
}

export default env
